export const FAQ = [
  {
    title: 'home.sectionTitle1',
    items: [
      {
        question: 'home.faqQuestion1',
        answer: 'home.faqAnswer1',
      },
      {
        question: 'home.faqQuestion2',
        answer: 'home.faqAnswer2',
      },
      {
        question: 'home.faqQuestion3',
        answer: 'home.faqAnswer3',
      },
      {
        question: 'home.faqQuestion4',
        answer: 'home.faqAnswer4',
      },
    ],
  },
  {
    title: 'home.sectionTitle2',
    items: [
      {
        question: 'home.faqQuestion5',
        answer: 'home.faqAnswer5',
      },
      {
        question: 'home.faqQuestion6',
        answer: 'home.faqAnswer6',
      },
      {
        question: 'home.faqQuestion7',
        answer: 'home.faqAnswer7',
      },
      {
        question: 'home.faqQuestion8',
        answer: 'home.faqAnswer8',
      },
    ],
  },
];
