import React from "react";
import { useTranslation } from "react-i18next";
import england from '../../assets/images/england.png';
import serbia from '../../assets/images/serbia.png';
import china from '../../assets/images/china.png';

import './LanguageSwitcher.scss';

const LanguageSwitcher = ({ closeMenu }) => {
    const { i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <div className="languages flex items-center space-x-4">
            <button
                onClick={() => changeLanguage("en")}
                className="flag"
            >
                <img src={england} alt="English" className="w-8 h-8" />
            </button>
            <button
                onClick={() => changeLanguage("sr")}
                className="flag"
            >
                <img src={serbia} alt="Serbian" className="w-8 h-8" />
            </button>
            <button onClick={() => changeLanguage("zh")} className="flag">
                <img src={china} alt="Chinese" className="w-8 h-8" />
            </button>
        </div>
    );
};

export default LanguageSwitcher;