export const IconPlus = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    fill="none"
    viewBox="0 0 26 26"
  >
    <path
      stroke="#fff"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M9 13h4m4 0h-4m0 0V9m0 4v4"
    />
    <rect width="24" height="24" x="1" y="1" stroke="#fff" rx="12" />
  </svg>
);
