import logo_light from '../assets/images/logo-light.png';
import React from 'react'
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function Footer() {
    const { t } = useTranslation();

    return (
        <>
            <footer className="footer relative text-gray-200 dark:text-gray-200">
                <div className="container">
                    <div className="grid grid-cols-12">
                        <div className="col-span-12">
                            <div className="py-[60px] px-0">
                                <div className="grid grid-cols-1">
                                    <div className="navbar-brand1">
                                        <span className="inline-block dark:hidden">
                                            <img src={logo_light} className="l-light w-5" alt="" />
                                        </span>
                                    </div>

                                    <ul className="list-none footer-list text-center mt-8">
                                        <li className="footer-link inline px-2"><Link to="/services"
                                            className="text-slate-950 hover:text-gray-400 duration-500 ease-in-out">{t('services')}</Link>
                                        </li>
                                        <li className="footer-link inline px-2 mt-[10px]"><Link to="/contactus"
                                            className="text-slate-950 hover:text-gray-400 duration-500 ease-in-out ms-2">{t('contact')}</Link></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*<div className="py-[30px] px-0 border-t border-slate-800">*/}
                {/*    <div className="container text-center">*/}
                {/*        <div className="flex justify-center">*/}
                {/*            <div className="md:col-span-6 md:mt-0 mt-8">*/}
                {/*                <ul className="list-none md:text-end text-center">*/}
                {/*                    <li className="inline"><Link to="https://1.envato.market/ovaxo-react" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-shopping-cart align-middle" title="Buy Now"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="https://dribbble.com/shreethemes" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-dribbble align-middle" title="dribbble"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="https://www.behance.net/shreethemes" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-behance" title="Behance"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="http://linkedin.com/company/shreethemes" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-linkedin" title="Linkedin"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="https://www.facebook.com/shreethemes" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-facebook-f align-middle" title="facebook"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="https://www.instagram.com/shreethemes/" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-instagram align-middle" title="instagram"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="https://twitter.com/shreethemes" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-twitter align-middle" title="twitter"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="mailto:support@shreethemes.in"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-envelope align-middle" title="email"></i></Link></li>*/}
                {/*                    <li className="inline ms-1"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank"*/}
                {/*                        className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide align-middle transition duration-500 ease-in-out border border-gray-800 rounded-md hover:border-violet-600 dark:hover:border-violet-600 hover:bg-violet-600 dark:hover:bg-violet-600"><i*/}
                {/*                            className="uil uil-file align-middle" title="customization"></i></Link></li>*/}
                {/*                </ul>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</div>*/}
                <div className="reserved">{t('footer_reserved')}</div>
            </footer>
        </>
    )
}
