import image from '../assets/images/client/01.jpg';
import image1 from '../assets/images/client/05.jpg';
import image2 from '../assets/images/client/02.jpg';
import image3 from '../assets/images/client/04.jpg';
import image4 from '../assets/images/client/03.jpg';
import image5 from '../assets/images/client/06.jpg';
import React from 'react'
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";

export default function ClientSreview() {
    const { t } = useTranslation();
    return (
        <>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="testi">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-6 md:text-2xl text-xl font-medium">{t('clients_review')}</h3>
                    </div>

                    <div className="grid lg:grid-cols-3 sm:grid-cols-2 grid-cols-1 mt-8 gap-[30px]">
                        <ul className="space-y-8">
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image}
                                        className="client-image cover h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Li Wei</Link>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <p className="text-slate-400">{t('review1')}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>

                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image1}
                                        className="client-image h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Chen Xiaoping</Link>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <p className="text-slate-400">{t('review2')}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>

                        <ul className="space-y-8 hidden sm:block">
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image2}
                                        className="client-image h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Zhang Min</Link>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <p className="text-slate-400">{t('review3')}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>

                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image3}
                                        className="client-image h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Wang Mei</Link>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <p className="text-slate-400">{t('review4')}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>

                        <ul className="space-y-8 hidden lg:block">
                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image4}
                                        className="client-image h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">Dongyang Huang</Link>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <p className="text-slate-400">{t('review5')}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>

                            <li className="rounded-lg shadow-lg dark:shadow-gray-800 p-6 bg-white dark:bg-slate-900">
                                <div className="flex items-center pb-6 border-b border-gray-100 dark:border-gray-800">
                                    <img src={image5}
                                        className="client-image h-16 w-16 rounded-full shadow dark:shadow-gray-800" alt="" />

                                    <div className="ps-4">
                                        <Link to="" className="text-lg h5 hover:text-violet-600 duration-500 ease-in-out">James Carter</Link>
                                    </div>
                                </div>

                                <div className="mt-6">
                                    <p className="text-slate-400">{t('review6')}</p>
                                    <ul className="list-none mb-0 text-amber-400 mt-2">
                                        <li className="inline"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                        <li className="inline ms-1"><i className="mdi mdi-star"></i></li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </>
    )
}
