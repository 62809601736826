import { useEffect, useRef, useState, MouseEvent } from 'react';
import { HomeFaqSingleQA } from './HomeFaqSingleQA/HomeFaqSingleQA';
import { FAQ } from './faqConstants';
import useWindowSize from '../useWindowSize';
import clsx from 'clsx';
import { useTranslation } from "react-i18next";

import styles from './HomeFaq.module.scss';

const HomeFaq = () => {
  const [openQAIndex, setOpenQAIndex] = useState(-1);
  const [openGroupIndex, setGroupIndex] = useState(0);
  const [animateGroupMargin, setAnimateGroupMargin] = useState(false);
  const [animateGroupOpacity, setAnimateGroupOpacity] = useState(false);
  const ref = useRef(null);
  const refNavigation = useRef(null);
  const windowSize = useWindowSize();
  const isMobileView = windowSize.width < 900;
  const { t } = useTranslation();

  const navigationItemClick = (
    e,
    index
  ) => {
    if (index !== openGroupIndex) {
      setAnimateGroupOpacity(false);
      setGroupIndex(index);
      setAnimateGroupMargin(true);
      setOpenQAIndex(-1);
    }
    if (isMobileView) {
      ref.current?.scrollIntoView();
      if (refNavigation.current)
        refNavigation.current.scrollLeft =
          e.currentTarget.offsetLeft -
          (window.innerWidth - e.currentTarget.clientWidth) / 2;
      const yOffset = -100;
      if (ref.current) {
        const topDistance =
          ref.current.getBoundingClientRect().top +
          window.pageYOffset +
          yOffset;
        window.scrollTo({ top: topDistance, behavior: 'smooth' });
      }
    }
  };

  useEffect(() => {
    if (animateGroupMargin) {
      const timer = setTimeout(() => {
        setAnimateGroupMargin(false);
      }, 80);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [animateGroupMargin]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAnimateGroupOpacity(true);
    }, 80);

    return () => {
      clearTimeout(timer);
    };
  }, [openGroupIndex]);

  return (
    <div className={styles['home-faq-wrapper']}>
      <div className={styles['home-faq-title']}>{t('home.faqTitle')}</div>
      <div className={styles['home-faq-inner']}>
        {/*<div*/}
        {/*  className={styles['home-faq-navigation-wrapper']}*/}
        {/*  ref={refNavigation}*/}
        {/*>*/}
        {/*  {FAQ.map((group, index) => (*/}
        {/*    <div*/}
        {/*      key={group.title + group.title}*/}
        {/*      className={clsx(styles['home-faq-navigation-item'], {*/}
        {/*        [styles.active]: openGroupIndex === index,*/}
        {/*      })}*/}
        {/*      onClick={(e) => navigationItemClick(e, index)}*/}
        {/*    >*/}
        {/*      {t(group.title)}*/}
        {/*    </div>*/}
        {/*  ))}*/}
        {/*</div>*/}
        <div
          className={clsx(styles['home-faq-content-wrapper'], {
            [styles.animate]: animateGroupMargin,
            [styles.animate1]: animateGroupOpacity,
          })}
          ref={ref}
        >
          {FAQ[openGroupIndex].items.map((question, index) => (
            <HomeFaqSingleQA
              key={question.question}
              qa={question}
              isOpen={openQAIndex === index}
              index={index}
              delay={index * 200}
              setOpenQAIndex={setOpenQAIndex}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeFaq;
