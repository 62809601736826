import React, {useEffect, useRef, useState} from 'react'
import Footer from '../component/footer';
import GetInTouch from '../component/get-in-touch';
import BlogsNews from '../component/blogs-news';
import ClientSreview from '../component/clientsreview';
import OurServices from '../component/our-services';
import About from '../component/about';
import cta from '../assets/images/cta.jpg';
import { Link } from "react-router-dom";
import NavbarSmallLight from '../component/navbar-small-light';
import "../../node_modules/react-modal-video/css/modal-video.css";
import {useTranslation} from "react-i18next";
import HomeFaq from "../component/HomeFaq/HomeFaq";
import Logo from "../assets/Logo";

export default function Index() {
    const [isOpen, setOpen] = useState(false)
    const { t } = useTranslation();

    return (
        <>
            <NavbarSmallLight />
            <section className="py-36 lg:py-56 w-full table relative bg-[url('../../assets/images/bg/bg-4.jpg')] bg-no-repeat" id="home">
                <div className="absolute inset-0 bg-gradient-to-t to-slate-950/50 via-slate-950/75 from-slate-950"></div>
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        {/*<Logo />*/}
                        <div className="greeting-wrapper">
                            <div className="font-2xl text-4xl mb-5 mt-10 text-white">
                                {t('greeting')}
                            </div>
                            <div>
                                <span className="font-2xl text-4xl mb-5 mt-10 text-white">Tailwind Trade</span>
                            </div>
                        </div>
                        <p className="text-slate-400 text-lg max-w-xl mx-auto">
                            {t('about')}
                        </p>
                        <div className="mt-8">
                            <Link to="/contactus" className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md"><i class="uil uil-envelope"></i> {t('contact')}</Link>
                        </div>
                    </div>
                </div>
            </section>
            <About />
            <OurServices short />
            <section className="relative md:py-24 py-16 md:pt-0 pt-0 absolute-wrapper-container">
                <HomeFaq />
                <div className="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 absolute-wrapper"></div>
            </section>
            <ClientSreview />
            <GetInTouch />
            <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.300659422295!2d20.459598376897873!3d44.81543917107072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7ab18a68a50b%3A0x5cdece6675c1bfbf!2z0JTQtdGH0LDQvdGB0LrQsCA0LCDQkdC10L7Qs9GA0LDQtCAxMTAwMA!5e0!3m2!1ssr!2srs!4v1732283487040!5m2!1ssr!2srs"
                            style={{border: 0}} className="w-full h-[500px]" title="contactUs" allowFullScreen></iframe>
                        {/*<iframe*/}
                        {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.300659422295!2d20.459598376897873!3d44.81543917107072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7ab18a68a50b%3A0x5cdece6675c1bfbf!2z0JTQtdGH0LDQvdGB0LrQsCA0LCDQkdC10L7Qs9GA0LDQtCAxMTAwMA!5e0!3m2!1ssr!2srs!4v1732283487040!5m2!1ssr!2srs"*/}
                        {/*    width="600" height="450" style="border:0;" allowFullScreen loading="lazy"*/}
                        {/*    referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
