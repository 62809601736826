import React from 'react'
import marko from '../assets/images/marko.jpeg';
import dusan from '../assets/images/dusan.jpeg';
import nikola from '../assets/images/nikola.jpeg';
import jelena from '../assets/images/jelena.jpeg';
import { Link } from "react-router-dom";
import { Facebook, Instagram, Linkedin } from 'react-feather';
import {useTranslation} from "react-i18next";

export default function MeetOurs() {
    const { t } = useTranslation();
    const MeetOursdata = [
        {
            image: marko,
            title: 'Marko Cvjetković',
            type: 'consultant',
            linked: 'https://www.linkedin.com/in/marko-cvjetkovic-757964314/'
        },
        {
            image: jelena,
            title: 'Jelena Fu',
            type: 'consultant',
            linked: 'https://www.linkedin.com/in/jelena77f/'
        },
        {
            image: dusan,
            title: 'Dušan Deliċ',
            type: 'advocate',
            linked: 'https://www.linkedin.com/in/dusan-delic-b6449a141/'
        },
        {
            image: nikola,
            title: 'Nikola Hanžek',
            type: 'advocate',
            linked: 'https://www.linkedin.com/in/nikola-han%C5%BEek-259809154/?originalSubdomain=rs'
        },
    ]
    return (
        <>
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">Meet Our Teammates</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">{t('our_team_about')}</p>
                </div>
                <div className="grid md:grid-cols-12 grid-cols-1 mt-8 gap-[30px]">
                    {MeetOursdata.map((item, index) => (
                        <div className="lg:col-span-3 md:col-span-6" key={index} >
                            <div className="group text-center">
                                <div className="relative inline-block mx-auto h-52 w-52 rounded-full overflow-hidden">
                                    <img src={item.image} className="" alt="" />

                                    <ul
                                        className="list-none absolute top-1/2 -translate-y-1/2 -end-20 group-hover:end-5 transition-all duration-500 ease-in-out">
                                        <li className="mt-1">
                                            <Link target="_blank" rel="noopener noreferrer" to={item.linked} className="h-8 w-8 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><Linkedin className="h-4 w-4"></Linkedin></Link></li>
                                    </ul>
                                </div>

                                <div className="content mt-3">
                                    <Link to=""
                                        className="text-lg hover:text-violet-600 transition-all duration-500 ease-in-out h5">{item.title}</Link>
                                    <p className="text-slate-400">{t(item.type)}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    )
}
