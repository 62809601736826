import contact from '../assets/images/contact.jpg';
import React, { useState } from 'react'
import ContactData from '../component/contact-data';
import Footer from '../component/footer';
import {useTranslation} from "react-i18next";
import NavbarSmallLight from "../component/navbar-small-light";
import emailjs from "emailjs-com";

export default function ContactUs() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [status, setStatus] = useState("");

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs
            .send(
                "service_4cwfkyw", // Replace with your service ID
                "template_5zjdpxj", // Replace with your template ID
                formData,
                "x3_Xs7Zt4uxm1nfvq" // Replace with your user ID from EmailJS
            )
            .then(
                () => setStatus("message_sent"),
                () => setStatus("message_fail")
            );
    };

    return (
        <>
            <NavbarSmallLight />
            <section className="py-28 w-full table relative bg-[url('../../assets/images/bg/bg-2.jpg')] bg-bottom bg-no-repeat" id="home">
                <div className="absolute inset-0 bg-slate-950/80"></div>

                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center mt-10">
                        <h3 className="font-medium leading-normal text-3xl mt-10 text-white">{t('contact')}</h3>
                    </div>
                </div>
            </section>
            <section className="relative lg:py-24 py-16">
                <div className="container">
                    <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                        <div className="lg:col-span-7 md:col-span-6">
                            <img src={contact} alt="" />
                        </div>

                        <div className="lg:col-span-5 md:col-span-6">
                            <div className="lg:ms-5">
                                <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                    <h3 className="mb-6 text-2xl leading-normal font-medium">{t('get_in_touch')} !</h3>

                                    <form method="post" name="myForm" id="myForm" onSubmit={handleSubmit}>
                                        <p className="mb-0" id="error-msg"></p>
                                        <div id="simple-msg"></div>
                                        <div className="grid lg:grid-cols-12 lg:gap-6">
                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="name" className="form-label font-medium">{t('name')}</label>
                                                <input value={formData.name} onChange={handleChange} name="name" id="name" type="text" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder={t('name')} />
                                            </div>

                                            <div className="lg:col-span-6 mb-5">
                                                <label htmlFor="email" className="form-label font-medium">{t('your_email')}</label>
                                                <input value={formData.email} onChange={handleChange} name="email" id="email" type="email" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder={t('email')} />
                                            </div>
                                        </div>

                                        <div className="grid grid-cols-1">
                                            <div className="mb-5">
                                                <label htmlFor="comments" className="form-label font-medium">{t('message')}</label>
                                                <textarea onChange={handleChange} value={formData.message} name="message" className="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder={t('message')} />
                                            </div>
                                        </div>
                                        <button type="submit" id="submit" name="send" className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md">{t('send_message')}</button>
                                        <div className="status" style={{ color: status === 'message_sent' ? 'green' : 'red'}}>{t(status)}</div>
                                    </form>
                                    {/*<span>{result}</span>*/}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ContactData />
            </section>
            <div className="container-fluid relative">
                <div className="grid grid-cols-1">
                    <div className="w-full leading-[0] border-0">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.300659422295!2d20.459598376897873!3d44.81543917107072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7ab18a68a50b%3A0x5cdece6675c1bfbf!2z0JTQtdGH0LDQvdGB0LrQsCA0LCDQkdC10L7Qs9GA0LDQtCAxMTAwMA!5e0!3m2!1ssr!2srs!4v1732283487040!5m2!1ssr!2srs"
                            style={{ border: 0 }} className="w-full h-[500px]" title="contactUs" allowfullscreen> </iframe>
                        {/*<iframe*/}
                        {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2830.300659422295!2d20.459598376897873!3d44.81543917107072!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x475a7ab18a68a50b%3A0x5cdece6675c1bfbf!2z0JTQtdGH0LDQvdGB0LrQsCA0LCDQkdC10L7Qs9GA0LDQtCAxMTAwMA!5e0!3m2!1ssr!2srs!4v1732283487040!5m2!1ssr!2srs"*/}
                        {/*    width="600" height="450" style="border:0;" allowFullScreen loading="lazy"*/}
                        {/*    referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}