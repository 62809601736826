import React from 'react';
import { Link } from "react-router-dom";
import { Hexagon } from 'react-feather';
import OurServices from "./our-services";

export default function Detail() {
    const services = [
        {
            icon: 'adjust-circle',
            title: 'Grow Your Business',
            description: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
        },
        {
            icon: 'circuit',
            title: 'Drive More Sales',
            description: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
        },
        {
            icon: 'fire',
            title: 'Handled By Expert',
            description: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
        },
        {
            icon: 'flower',
            title: 'Increase Conversion',
            description: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
        },
        {
            icon: 'shopping-basket',
            title: 'Drive More Sales',
            description: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
        },
        {
            icon: 'flip-h',
            title: 'Sales Growth Idea',
            description: "If the distribution of letters and 'words' is random, the reader will not be distracted from making.",
        }
    ];


    return (
        <>
            <OurServices />
        </>
    )
}
