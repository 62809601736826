import { useRef } from 'react';
import { IconMinus } from "../../../assets/IconMinus/IconMinus";
import { IconPlus } from "../../../assets/IconPlus/IconPlus";
import clsx from 'clsx';
import { useTranslation } from "react-i18next";

import styles from './HomeFaqSingleQA.module.scss';

export const HomeFaqSingleQA = ({
  qa,
  index,
  isOpen,
  setOpenQAIndex,
}) => {
  const ref = useRef(null);
  const { t } = useTranslation();

  return (
    <div
      className={clsx(styles['home-faq-single-qa-wrapper'], {
        [styles.active]: isOpen,
      })}
      data-testid="single-qa-question"
      onClick={() => setOpenQAIndex(isOpen ? -1 : index)}
    >
      <div className={styles['landing-qa-section-single-qa-visible-wrapper']}>
        <div
          className={styles['landing-qa-section-single-qa-visible-question']}
        >
          {t(qa.question)}
        </div>

        {isOpen ? <IconMinus /> : <IconPlus />}
      </div>

      <div
        className={styles['landing-qa-section-single-qa-answer-wrapper']}
        style={{ height: isOpen ? ref.current?.clientHeight || 0 : 0 }}
      >
        <div
          ref={ref}
          className={styles['landing-qa-section-single-qa-answer-content']}
        >
          {t(qa.answer)}
        </div>
      </div>
    </div>
  );
};
