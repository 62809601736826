import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./locales/en.json";
import sr from "./locales/sr.json";
import zh from "./locales/zh.json";

i18n.use(initReactI18next).init({
    resources: {
        en: { translation: en },
        sr: { translation: sr },
        zh: { translation: zh },
    },
    lng: "en", // Default language
    fallbackLng: "en",
    interpolation: {
        escapeValue: false, // React already escapes values
    },
});

export default i18n;