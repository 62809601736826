import React, {useState} from 'react'
import { Link } from "react-router-dom";
import {useTranslation} from "react-i18next";
import emailjs from "emailjs-com";

export default function GetInTouch() {
    const { t } = useTranslation();
    const [formData, setFormData] = useState({ name: "", email: "", message: "" });
    const [status, setStatus] = useState("");

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        emailjs
            .send(
                "service_4cwfkyw", // Replace with your service ID
                "template_5zjdpxj", // Replace with your template ID
                formData,
                "x3_Xs7Zt4uxm1nfvq" // Replace with your user ID from EmailJS
            )
            .then(
                () => setStatus("message_sent"),
                () => setStatus("message_fail")
            );
    };

    return (
        <>
            <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
                <div className="container">
                    <div className="grid grid-cols-1 pb-8 text-center">
                        <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('get_in_touch')}</h3>
                        <p className="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">{t('get_in_touch_desc')}</p>
                    </div>
                    <div className="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
                        <div className="lg:col-span-8">
                            <div className="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                                <form method="post" name="myForm" id="myForm" onSubmit={handleSubmit}>
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="name" className="form-label font-medium">{t('name')}</label>
                                            <input value={formData.name} onChange={handleChange} name="name" id="name" type="text" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder={t('name')} />
                                        </div>

                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="email" className="form-label font-medium">{t('your_email')}</label>
                                            <input value={formData.email} onChange={handleChange} name="email" id="email" type="email" className="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2" placeholder={t('email')} />
                                        </div>
                                    </div>

                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <label htmlFor="comments" className="form-label font-medium">{t('message')}</label>
                                            <textarea onChange={handleChange} value={formData.message} name="message" className="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 mt-2 textarea h-28" placeholder={t('message')} />
                                        </div>
                                    </div>
                                    <button type="submit" id="submit" name="send" className="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md">{t('send_message')}</button>
                                    <div className="status" style={{ color: status === 'message_sent' ? 'green' : 'red'}}>{t(status)}</div>
                                </form>
                            </div>
                        </div>

                        <div className="lg:col-span-4">
                            <div className="lg:ms-8">
                                <div className="flex">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">{t('phone')}</h5>
                                        <Link to="tel:+152534-468-854" className="text-slate-400">+381 603 820 099</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">{t('email')}</h5>
                                        <Link to="mailto:office@tailwindtrade.com" className="text-slate-400">office@tailwindtrade.com</Link>
                                    </div>
                                </div>

                                <div className="flex mt-4">
                                    <div className="icons text-center mx-auto">
                                        <i className="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                    </div>

                                    <div className="flex-1 ms-6">
                                        <h5 className="text-lg dark:text-white mb-2 font-medium">{t('location')}</h5>
                                        <p className="text-slate-400 mb-2">Dečanska 4, 11000 {t('belgrade')}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
