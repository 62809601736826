import React from 'react';
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {SingleService} from "./SingleService/SingleService";

const OurServices = ({ short }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const services = [
        {
            icon: 'adjust-circle',
            title: 'service1',
            description: ["service1_desc", "service1_desc1", "service1_desc2"],
            delay: 0.2,
        },
        {
            icon: 'circuit',
            title: 'service2',
            description: ["service2_desc", "service2_desc1", "service2_desc2"],
            delay: 0.3,
        },
        {
            icon: 'fire',
            title: 'service3',
            description: ["service3_desc", "service3_desc1", "service3_desc2"],
            delay: 0.4,
        },
        {
            icon: 'flower',
            title: 'service4',
            description: ["service4_desc", "service4_desc1", "service4_desc2"],
            delay: 0.5,
        },
        {
            icon: 'shopping-basket',
            title: 'service5',
            description: ["service5_desc", "service5_desc1", "service5_desc2"],
            delay: 0.6,
        },
        {
            icon: 'flip-h',
            title: 'service6',
            description: ["service6_desc", "service6_desc1"],
            delay: 0.7,
        },
        {
            icon: 'flip-h',
            title: 'service7',
            description: ["service7_desc", "service7_desc1", "service7_desc2"],
            delay: 0.8,
        },
        {
            icon: 'flip-h',
            title: 'service8',
            description: ["service8_desc", "service8_desc1"],
            delay: 0.9,
        }
    ];
    return (
        <section className="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="features">
            <div className="container lg mx-auto">
                <div className="grid grid-cols-1 pb-8 text-center">
                    <h3 className="mb-4 md:text-2xl text-xl font-medium">{t('our_services')}</h3>
                    <p className="text-slate-400 max-w-xl mx-auto">
                        {t('our_services_desc')}
                    </p>
                </div>

                <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-4 gap-[30px]">
                    {/*{services.map((service, index) => {*/}
                    {/*    return (*/}
                    {/*        <div*/}
                    {/*            ref={ref}*/}
                    {/*            key={index}*/}
                    {/*            onClick={() => short ? navigate('/services') : navigate('/contactus')}*/}
                    {/*            className={`service-link group relative lg:px-6 mt-4 transition duration-900 ease-in-out rounded-xl overflow-hidden text-center transition-opacity transform duration-500 ${*/}
                    {/*                inView*/}
                    {/*                    ? `opacity-100 translate-y-0 delay-${index * 200}`*/}
                    {/*                    : "opacity-0 translate-y-5"*/}
                    {/*            }`}*/}
                    {/*        >*/}
                    {/*            <div className="relative overflow-hidden text-transparent -m-3">*/}
                    {/*                <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"*/}
                    {/*                ></Hexagon>*/}
                    {/*                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">*/}
                    {/*                    <i className={`uil uil-${service.icon}`}></i>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <div className="mt-6">*/}
                    {/*                <Link to="/services" className="service-title text-lg h5 transition duration-500 ease-in-out hover:text-violet-600" >*/}
                    {/*                    {t(service.title)}*/}
                    {/*                </Link>*/}
                    {/*                <p className="text-slate-400 transition duration-500 ease-in-out mt-3">*/}
                    {/*                    <ul className="service-wrapper">*/}
                    {/*                        {short ? <li>{t(service.description[0])}</li> : service.description.map((item) => (<li>{t(item)}</li>))}*/}
                    {/*                    </ul>*/}
                    {/*                </p>*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*    )*/}
                    {/*})}*/}
                    {
                        services.map((service, index) => <SingleService key={index} service={service} delay={index * 200} short={short} />)
                    }
                </div>
            </div>
        </section>
    )
};

export default OurServices;