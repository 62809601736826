import {Hexagon} from "react-feather";
import {Link, useNavigate} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import { useInView } from "react-intersection-observer";

import './SingleService.scss';

export const SingleService = ({ service, short, delay }) => {
    const [isVisible, setIsVisible] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const { ref, inView } = useInView({
        triggerOnce: true,
        threshold: 0.9, // Adjust to trigger the animation earlier or later
    });

    return (
        <div
            ref={ref}
            onClick={() => short ? navigate('/services') : navigate('/contactus')}
            className={`service-link group relative lg:px-6 mt-4 transition duration-900 ease-in-out rounded-xl overflow-hidden text-center transition-opacity transform duration-900 ${
                inView
                    ? `opacity-100 translate-y-0 delay-${delay * 200}`
                    : "opacity-0 translate-y-5"
            }`}
        >
            <div className="relative overflow-hidden text-transparent -m-3">
                <Hexagon className="h-28 w-28 fill-violet-600/5 mx-auto rotate-[30deg]"
                ></Hexagon>
                <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-violet-600 rounded-xl transition duration-500 ease-in-out text-3xl flex align-middle justify-center items-center">
                    <i className={`uil uil-${service.icon}`}></i>
                </div>
            </div>
            <div className="mt-6">
                <Link to="/services" className="service-title text-lg h5 transition duration-500 ease-in-out hover:text-violet-600" >
                    {t(service.title)}
                </Link>
                <p className="text-slate-400 transition duration-500 ease-in-out mt-3">
                    <ul className="service-wrapper">
                        {short ? <li>{t(service.description[0])}</li> : service.description.map((item) => (<li>{t(item)}</li>))}
                    </ul>
                </p>
            </div>
        </div>
    );
};