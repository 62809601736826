import logo_light from '../assets/images/logo-light.png';
import React, {useState, useEffect, useRef} from 'react'
import { Link } from 'react-router-dom';
import LanguageSwitcher from "./LanguageSwitcher/LanguageSwitcher";
import {useTranslation} from "react-i18next";

export default function NavbarSmallLight() {
   const [isOpen, setMenu] = useState(true);
   const { t } = useTranslation();
    const menuRef = useRef(null);

    window.addEventListener("scroll", windowScroll);
    useEffect(() => {
    }, []);

    function windowScroll() {
        const navbar = document.getElementById("navbar");
        if (
            document.body.scrollTop >= 50 ||
            document.documentElement.scrollTop >= 50
        ) {
            if (navbar !== null) {
                navbar?.classList.add("is-sticky");
            }
        } else {
            if (navbar !== null) {
                navbar?.classList.remove("is-sticky");
            }
        }

        const mybutton = document.getElementById("back-to-top");
        if (mybutton != null) {
            if (document.body.scrollTop > 500 || document.documentElement.scrollTop > 500) {
                mybutton.classList.add("flex");
                mybutton.classList.remove("hidden");
            } else {
                mybutton.classList.add("hidden");
                mybutton.classList.remove("flex");
            }
        }
    }
    const toggleMenu = () => {
        setMenu(!isOpen)
    }

    const closeMenu = () => {
        setMenu(true);
    }

    const handleOutsideClick = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) return setMenu(true); // Close the menu when clicking outside
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleOutsideClick);
        return () => {
            document.removeEventListener('mousedown', handleOutsideClick);
        };
    }, []);
    return (
        <nav ref={menuRef} className="navbar" id="navbar">
            <div className="container flex flex-wrap items-center justify-end">
                <a className="navbar-brand md:me-8" href="/index">
                        <span className="inline-block dark:hidden">
                            <img src={logo_light} className="l-dark" alt="" />
                            <img src={logo_light} className="l-light w-5" alt="" />
                        </span>
                    <img src={logo_light} className="hidden dark:inline-block" alt="" />
                </a>

                <div className="nav-icons flex items-center lg_992:order-2 ms-auto lg:ms-4">

                    {/*<ul className="list-none menu-social mb-0">*/}
                    {/*    <li className="inline">*/}
                    {/*        <Link to="/login" */}
                    {/*            className="h-9 w-9 inline-flex items-center text-center justify-center text-base font-normal tracking-wide border align-middle transition duration-500 ease-in-out rounded-full bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white"><i*/}
                    {/*                className="uil uil-user"></i></Link>*/}
                    {/*    </li>*/}
                    {/*</ul>*/}

                    <button data-collapse="menu-collapse" type="button" onClick={toggleMenu}
                            className="collapse-btn inline-flex items-center ms-3 text-slate-950 dark:text-white lg_992:hidden"
                            aria-controls="menu-collapse" aria-expanded="false">
                        <span className="sr-only">Navigation Menu</span>
                        <i className="mdi mdi-menu text-[24px]"></i>
                    </button>
                </div>
                <div className={`${isOpen === true ? 'navigation lg_992:order-1 lg_992:flex hidden ms-auto' : 'navigation lg_992:order-1 lg_992:flex block ms-auto'}`} id="menu-collapse">
                    <ul className="navbar-nav nav-light" id="navbar-navlist">
                        <li className={`nav-item`}>
                            <Link to="/" onClick={closeMenu} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">{t('home')}</Link>
                        </li>
                        <li className={`nav-item`}>
                            <Link to="/aboutus" onClick={closeMenu} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">{t('about_us')}</Link>
                        </li>
                        <li className={`nav-item`}>
                            <Link to="/services" onClick={closeMenu} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">{t('services')}</Link>
                        </li>
                        <li className={`nav-item`}>
                            <Link to="/contactus" onClick={closeMenu} activeClass="active" spy={true} smooth={true} duration={500} className="nav-link">{t('contact')}</Link>
                        </li>
                    </ul>
                    <LanguageSwitcher closeMenu={closeMenu} />
                </div>
            </div>
        </nav>
    )
}
